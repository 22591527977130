import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  @media (min-height: 840px) {
    @media (min-width: 560px) {
      padding: 32px;
    }
  }

  div.content {
    min-height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    background: #000;
    height: 100%;
    width: 100%;
    cursor: default;

    @media (min-height: 840px) {
      @media (min-width: 560px) {
        min-height: calc(100vh - 66px);
        border-radius: 22px;
      }
    }

    .icons.right {
      right: 0;
    }

    .icons {
      display: none;

      @media (min-width: 941px) {
        height: -webkit-fill-available;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      @media (max-height: 469px) {
        display: none;
      }
    }

    div.text {
      display: inline-block;

      h1 {
        font-size: 64px;
        color: #fff;
      }

      h1.small {
        font-size: 50px;

        @media (max-width: 1280px) {
          font-size: 40px;
        }
      }

      p {
        font-size: 23px;
      }
    }
  }

  div.leftContent {
    display: none;

    @media (min-width: 960px) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      width: 50%;
      z-index: 1;
    }
  }

  div.rightContent {
    padding: 45px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #fff;
    border-radius: 22px;
    z-index: 1;

    @media (min-width: 940px) {
      max-width: 390px;
    }
    @media (max-width: 410px) {
      padding: 30px;
    }

    .logo {
      cursor: pointer;
      @media (max-height: 700px) {
        svg {
          height: 50px;
        }
      }

      @media (max-width: 400px) {
        svg {
          height: 50px;
        }
      }

      @media (max-width: 330px) {
        svg {
          height: 42px;
        }
      }
    }
  }

  .rightContent {
    width: 96%;
    max-width: 390px;
    margin: 0 auto;
  }

  .title {
    font-size: 62px;
    color: #000;
  }

  .title.white {
    color: ${props => props.theme.colors.white};
    margin-bottom: 12px;
  }

  .description {
    color: ${props => props.theme.colors.white};
    font-size: 18px;
  }

  .subTitle {
    color: #000;
    padding: 20px 0;
    text-align: center;

    a {
      font-weight: bold;
      color: #000;
    }

    @media (max-height: 690px) {
      padding: 12px 0;
    }

    strong {
      cursor: pointer;
    }

    .orangeText {
      color: #fe501c;
    }
  }

  .subTitle.orange {
    color: ${props => props.theme.colors.primary.main};
    cursor: pointer;
  }

  .titleBack {
    margin-top: 18px;
  }

  .warningTitle {
    color: #ec1d24;
    margin-top: 12px;
  }

  .socialButtons {
    display: flex;
    justify-content: center;
    margin-bottom: 12px;

    @media (max-width: 330px) {
      flex-direction: column;
      width: 100%;

      button.facebook {
        margin-bottom: 12px;
        margin-right: 0 !important;
      }
    }

    @media (max-width: 380px) {
      button {
        min-width: 120px !important;
      }
    }

    button {
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 150px;
      padding: 10px;
      border-radius: 8px;
      transition: box-shadow 0.08s ease-in-out, transform 0.08s ease-in-out;

      &:hover,
      &:focus {
        opacity: 0.9;
        outline: 0;
      }

      &:active {
        box-shadow: none !important;
        transform: translate3d(4px, 4px, 0px);
      }
    }

    button.facebook {
      background: #005cb9;
      margin-right: 12px;
      box-shadow: rgb(255 255 255) 4px 4px 0px -1px, rgb(0 92 185) 4px 4px;
    }

    button.google {
      background: #ec1d24;
      box-shadow: rgb(255 255 255) 4px 4px 0px -1px, rgb(236 29 36) 4px 4px;
    }
  }

  form.register {
    width: 100%;
    display: flex;
    flex-direction: column;

    .alignInput {
      margin-bottom: 14px;
    }

    input {
      height: 44px;
    }
  }

  form.register.passField {
    padding: 0.8rem 0;
  }

  form.register.codeField {
    .subTitle {
      font-size: 13px;
    }
    .formContent {
      margin: 0.8rem auto;
    }
  }

  span.error {
    color: #ec1d24;
    text-align: center;
    margin-bottom: 12px;
  }

  button.formButton {
    width: 100%;
    height: 48px;
    border-radius: 6px;
    color: #fff;

    .loading {
      color: #fff;
    }
  }

  button.SingupButton {
    height: 48px;
    margin-bottom: 10px;
  }

  div.alignDivider {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
  }

  div.alignDivider > span {
    color: ${props => props.theme.colors.primary.main};
    padding: 0 8px;
  }

  div.divider {
    height: 1px;
    width: 100%;
    background: #707070;
  }

  div.divider.padding {
    margin-bottom: 12px;
  }

  .alignButton {
    margin-top: 20px;
  }
`;
