import FacebookLogin, {
  LoginResponse,
} from '@greatsumini/react-facebook-login';
import CircularProgress from '@mui/material/CircularProgress';
import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { useEffect, useState } from 'react';

import facebookIcon from '../../assets/facebook-social-icon.png';
import googleIcon from '../../assets/google-social-icon.png';
import LockIcon from '../../assets/lock.svg';
import Logo from '../../assets/logo-novo-cointimes.svg';
import MailIcon from '../../assets/mail.svg';

import { t } from 'i18next';
import Head from 'next/head';
import { Input } from '../../components/UI';
import { FlatButton } from '../../components/UI/Button/styles';
import { useAuth } from '../../hooks/auth';
import { FormProvider, useForm } from '../../hooks/form';
import { LoginShortRequest } from '../../models/auth';
import { Container } from '../../styles/pages/login/styles';

const Login: React.FC<React.PropsWithChildren<unknown>> & { layout: string } = () => {
  const [loginError, setLoginError] = useState('');
  const [loading, setLoading] = useState(false);

  const methods = useForm();
  const {
    handleSubmit,
    formState: { errors },
  } = methods;
  const { login, socialLogin, logout } = useAuth();
  const router = useRouter();

  const { from } = router.query;
  const redirectTo =
    from && from.toString().startsWith('/') ? from.toString() : undefined;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const referralCode = urlParams.get('referral_code');

    if (referralCode) {
      localStorage.setItem('referral_code', referralCode);
    }

    const token = localStorage.getItem('@coingoback:token');
    if (token) {
      logout();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = async (data: LoginShortRequest) => {
    try {
      setLoading(true);

      await login(data);

      const device_id = localStorage.getItem('@coingoback:currentDeviceIdToLink');

      if (device_id) {
        router.push(`/device?device_id=${device_id}`);
      } else if (redirectTo) {
        router.push(redirectTo);
      } else {
        router.push('/home');
      }

    } catch (error) {
      setLoading(false);

      if (error?.message) {
        setLoginError(error.message);
      }
    }
  };

  const onGoogleLogin = async (data: TokenResponse) => {
    if (data.access_token) {
      try {
        setLoading(true);

        await socialLogin({
          provider: 'google',
          access_token: data.access_token ?? '',
          referral_code: localStorage.getItem('referral_code'),
        });

        const device_id = localStorage.getItem('@coingoback:currentDeviceIdToLink');

        if (device_id) {
          router.push(`/device?device_id=${device_id}`);
        } else if (redirectTo) {
          router.push(redirectTo);
        } else {
          router.push('/home');
        }
      } catch (error) {
        setLoading(false);

        if (error?.message) {
          setLoginError(error.message);
        }
      }
    }
  };

  const onFacebookLogin = async (data: LoginResponse['authResponse']) => {
    if (data.accessToken) {
      try {
        setLoading(true);

        await socialLogin({
          provider: 'facebook',
          access_token: data.accessToken ?? '',
          referral_code: localStorage.getItem('referral_code'),
        });

        if (redirectTo) {
          router.push(redirectTo);
        } else {
          router.push('/home');
        }
      } catch (error) {
        setLoading(false);

        if (error?.message) {
          setLoginError(error.message);
        }
      }
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: onGoogleLogin,
  });

  return (
    (<Container>
      <Head>
        <title>{t('pages.login.sign-in')} | Cointimes</title>
        <meta
          name="description"
          content="Ganhe bitcoin com criptoback através de cashback nas maiores lojas do Brasil. "
        />
      </Head>
      <div className="content">
        {/* <div className="leftContent padding">
          <img src={campaign58DiasComCrypto} style={{maxWidth: '100%', minWidth: '100%'}}/>
        </div> */}
        <div className="rightContent">
          <Link href="/" passHref>
            <div className="logo">
              <Logo height={50} />
            </div>
          </Link>
          <span className="subTitle">{t('pages.login.connect-with-your-social-network')}</span>
          <div className="socialButtons">
            <FacebookLogin
              appId={process.env.NEXT_PUBLIC_FACEBOOK_CLIENT_ID || ''}
              onSuccess={onFacebookLogin}
              render={({ onClick }) => (
                <button className="facebook" onClick={onClick}>
                  <img src={facebookIcon.src} alt="Facebook Icon" />
                </button>
              )}
            />
            <button className="google" onClick={() => googleLogin()}>
              <img src={googleIcon.src} alt="Google Icon" />
            </button>
          </div>
          <div className="alignDivider">
            <div className="divider"></div>
            <span>{t('pages.login.or')}</span>
            <div className="divider"></div>
          </div>
          <FormProvider {...methods}>
            <form className="register" onSubmit={handleSubmit(handleLogin)}>
              <div className="alignInput">
                <Input.Default
                  icon={<MailIcon />}
                  type="text"
                  placeholder={t('pages.login.email-input')}
                  registerField={{
                    name: 'username',
                    options: {
                      required: t('pages.login.email-input-required'),
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: t('pages.login.email-input-invalid'),
                      },
                    },
                  }}
                />
              </div>
              <div className="alignInput">
                <Input.Default
                  icon={<LockIcon />}
                  type="password"
                  placeholder={t('pages.login.password-input')}
                  registerField={{
                    name: 'password',
                    options: {
                      required: t('pages.login.password-input-required'),
                    },
                  }}
                />
              </div>
              {errors.username ? (
                <span className="error">{errors.username.message}</span>
              ) : errors.password ? (
                <span className="error">{errors.password.message}</span>
              ) : (
                loginError !== '' && <span className="error">{loginError}</span>
              )}
              <FlatButton className="formButton" type="submit">
                {loading ? (
                  <CircularProgress size={24} className="loading" />
                ) : (
                  t('pages.login.sign-in')
                )}
              </FlatButton>
            </form>
          </FormProvider>
          <Link href="/forgot-password" passHref>
            <span className="subTitle orange">{t('pages.login.forgot-my-password')}</span>
          </Link>
          <div className="divider padding"></div>
          <Link href="/signup" passHref>
            <FlatButton className="SingupButton" $light type="submit" style={{ width: '100%' }}>
              {t('pages.login.sign-up')}
            </FlatButton>
          </Link>
        </div>
      </div>
    </Container>)
  );
};

Login.layout = 'login';
export default Login;
